<template>

  <v-footer fluid padless>
  
        <v-card
      flat
      tile
      class="grey darken-4 white--text text-left flex"
      fluid
    >
    <v-row class="pa-2">

          <v-col>
      <v-card-text>
        <h3 class="mb-1">Leistungen:</h3>

        <v-container>
        <v-row>
          Archäologische Ausgrabung
        </v-row>
        <v-row>
          Archäologische Voruntersuchung
        </v-row>
        <v-row>
          Archäologische Begleitung Oberbodenabtrag
        </v-row>
        </v-container>

      </v-card-text>
          </v-col>


          <v-col>
      <v-card-text>
        <h3 class="mb-1">Kontakt:</h3>

        <v-container>
            <v-row>
          <a style="color:white;text-decoration:none" href="tel:">

              <span class="pr-4">Tel.:</span>

              <span>+&thinsp;</span>

          </a>
</v-row>

        <v-row>
<a style="color:white;text-decoration:none" href="tel:"><span>
          Mobil: +&thinsp;</span></a>
</v-row>


        <v-row>
          <a style="color:white;text-decoration:none" href="mailto:info@wanax.de"><span>E-Mail: info@wanax.de</span></a>
</v-row>
        </v-container>
      </v-card-text>
          </v-col>




      </v-row>



    </v-card>

  </v-footer>

</template>
<script>
export default {
  name: "AppFooter",
}
</script>
